import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import indexStyles from '../styles/index.module.css';

const IndexPage = () => {
  let { logoImage, appStoreImage, googlePlayImage } = useStaticQuery(
    graphql`
      query {
        logoImage: file(relativePath: { eq: "major-mazer-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        appStoreImage: file(relativePath: { eq: "app-store-badge.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        googlePlayImage: file(relativePath: { eq: "google-play-badge.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <SEO title={'Home'} />
      <div className={indexStyles.container}>
        <Img
          alt={'Major Mazer Logo'}
          className={indexStyles.imageLogo}
          fadeIn={false}
          fluid={logoImage.childImageSharp.fluid}
          placeholderStyle={{ opacity: 0 }}
        />
        <div style={{ height: '2rem' }} />
        <span className={indexStyles.textBody}>
          Available now on Android and iOS.
        </span>
        <div style={{ height: '2rem' }} />
        <div className={indexStyles.containerBadges}>
          <a href={'https://apps.apple.com/us/app/id1528433344'}>
            <Img
              alt={'App Store Badge'}
              className={indexStyles.imageBadgeApple}
              fadeIn={false}
              fluid={appStoreImage.childImageSharp.fluid}
              placeholderStyle={{ opacity: 0 }}
            />
          </a>
          <div style={{ width: '1rem', height: '1rem' }} />
          <a
            href={
              'https://play.google.com/store/apps/details?id=com.majormazer.app'
            }
          >
            <Img
              alt={'Play Store Badge'}
              className={indexStyles.imageBadgeGoogle}
              fadeIn={false}
              fluid={googlePlayImage.childImageSharp.fluid}
              placeholderStyle={{ opacity: 0 }}
            />
          </a>
        </div>
        <div style={{ height: '2rem' }} />
        <Link to="/support" className={indexStyles.supportButton}>
          <span className={indexStyles.textSupport}>Support Us</span>
        </Link>
      </div>
    </Layout>
  );
};

export default IndexPage;
